<template>
	<el-form class="page" :model="kuponi" :rules="rules" ref="form" :label-position="'top'">
		<el-row>
			<el-col>
				<el-form-item size="large">
					<el-tooltip class="item" effect="dark" :content="$t('global.nazad')" placement="top">
						<el-button @click="$utils('goBack')" type="info" icon="fas fa-hand-point-left" circle></el-button>
					</el-tooltip>
				</el-form-item>
			</el-col>
		</el-row>
		<el-row>
			<el-col :xs="24" :sm="8" :md="6" :lg="6" :xl="6">
				<el-form-item :label="$t('kuponi.naziv')" prop="naziv">
					<el-input v-model="kuponi.naziv" :maxLength="250" :disabled="id > 0"/>
				</el-form-item>
			</el-col>
			<el-col :xs="24" :sm="8" :md="6" :lg="6" :xl="3">
				<el-form-item :label="$t('kuponi.vrsta')">
					<el-switch
							:disabled="id > 0"
							v-model="kuponi.vrsta"
							active-value="fiksni"
							inactive-value="multi"
							:active-text="$t('kuponi.fiksni')"
							:inactive-text="$t('kuponi.multi')"/>
				</el-form-item>
			</el-col>
			<el-col :xs="24" :sm="8" :md="6" :lg="4" :xl="4">
				<el-form-item :label="$t('kuponi.datumVremeOd')" prop="datum_vreme_od" class="width-full">
					<el-date-picker
							:picker-options="pickerOptionsOd"
							:disabled="id > 0"
							:clearable="false"
							v-model="kuponi.datum_vreme_od"
							type="datetime"
							value-format="yyyy-MM-dd HH:mm:ss"
							format="dd.MM.yyyy HH:mm">
					</el-date-picker>
				</el-form-item>
			</el-col>
			<el-col :xs="24" :sm="8" :md="6" :lg="4" :xl="4">
				<el-form-item :label="$t('kuponi.datumVremeDo')" prop="datum_vreme_do" class="width-full">
					<el-date-picker
							:picker-options="pickerOptionsDo"
							:disabled="id > 0"
							:clearable="false"
							v-model="kuponi.datum_vreme_do"
							type="datetime"
							value-format="yyyy-MM-dd HH:mm:ss"
							format="dd.MM.yyyy HH:mm">
					</el-date-picker>
				</el-form-item>
			</el-col>
		</el-row>
		<el-row>
			<el-col>
				<el-col :xs="24" :sm="8" :md="6" :lg="6" :xl="4">
					<el-form-item :label="$t('kuponi.kolicina')" class="width-full">
						<el-input-number :min="1" v-model="kuponi.kolicina" :disabled="id > 0"/>
					</el-form-item>
				</el-col>
			</el-col>
			<el-col :xs="24" :sm="8" :md="6" :lg="6" :xl="8">
				<el-row>
					<el-col :span="6">
						<el-form-item :label="$t('kuponi.prefix')">
							<el-input v-model="kuponi.prefix" placeholder="$$-" :disabled="id > 0"/>
						</el-form-item>
					</el-col>
					<el-col :span="6">
						<el-form-item :label="$t('kuponi.sablon')" prop="sablon">
							<el-input v-model="kuponi.sablon" placeholder="$$$-$$$" :disabled="id > 0"/>
						</el-form-item>
					</el-col>
					<el-col :span="6">
						<el-form-item :label="$t('kuponi.sufix')">
							<el-input v-model="kuponi.sufix" placeholder="-$$" :disabled="id > 0"/>
						</el-form-item>
					</el-col>
					<el-col :span="6" v-if="id > 0">
						<el-form-item label=" ">
							{{kuponi.kupon}}
						</el-form-item>
					</el-col>
				</el-row>
			</el-col>
		</el-row>
		<el-row>
			<el-col :xs="24" :sm="8" :md="6" :lg="4" :xl="3">
				<el-form-item :label="$t('kuponi.fiksniPopust')" class="width-full" prop="fiksni_popust">
					<el-input-number :min="0" :step="10" v-model="kuponi.fiksni_popust" :disabled="id > 0 || (kuponi.procenat_popust > 0 || kuponi.fiksna_cena > 0)"/>
				</el-form-item>
			</el-col>
			<el-col :xs="24" :sm="8" :md="6" :lg="4" :xl="3">
				<el-form-item :label="$t('kuponi.procenatPopust')" class="width-full" prop="procenat_popust">
					<el-input-number :min="0" :step="1" :precision="0" v-model="kuponi.procenat_popust" :disabled="id > 0 || (kuponi.fiksni_popust > 0 || kuponi.fiksna_cena > 0)"/>
				</el-form-item>
			</el-col>
			<el-col :xs="24" :sm="8" :md="6" :lg="4" :xl="3">
				<el-form-item :label="$t('kuponi.fiksnaCena')" class="width-full" prop="fiksna_cena">
					<el-input-number :min="0" :step="10" v-model="kuponi.fiksna_cena" :disabled="id > 0 || (kuponi.fiksni_popust > 0 || kuponi.procenat_popust > 0)"/>
				</el-form-item>
			</el-col>
			<el-col :xs="24" :sm="8" :md="6" :lg="4" :xl="3">
				<el-form-item :label="$t('kuponi.minCena')" class="width-full" prop="min_cena">
					<el-input-number :min="0" :step="10" v-model="kuponi.min_cena" :disabled="id > 0"/>
				</el-form-item>
			</el-col>
		</el-row>
		<el-row>
			<el-col :xs="24" :sm="8" :md="8" :lg="8" :xl="8">
				<el-form-item :label="$t('kuponi.linije')" prop="id_vezane_linije" class="width-full">
					<select-vezane-linije class="full" :multiple="true" v-model="kuponi.id_vezane_linije" ref="linija" :disabled="id > 0"/>
				</el-form-item>
			</el-col>
			<el-col :xs="24" :sm="8" :md="6" :lg="4" :xl="3">
				<el-form-item :label="$t('kuponi.kartaDatumVremeOd')" prop="karta_datum_od" class="width-full">
					<el-date-picker
							:picker-options="pickerOptionsKartaOd"
							:disabled="id > 0"
							:clearable="false"
							v-model="kuponi.karta_datum_od"
							type="datetime"
							value-format="yyyy-MM-dd HH:mm:ss"
							format="dd.MM.yyyy HH:mm">
					</el-date-picker>
				</el-form-item>
			</el-col>
			<el-col :xs="24" :sm="8" :md="6" :lg="4" :xl="3">
				<el-form-item :label="$t('kuponi.kartaDatumVremeDo')" prop="karta_datum_do" class="width-full">
					<el-date-picker
							:picker-options="pickerOptionsKartaDo"
							:disabled="id > 0"
							:clearable="false"
							v-model="kuponi.karta_datum_do"
							type="datetime"
							value-format="yyyy-MM-dd HH:mm:ss"
							format="dd.MM.yyyy HH:mm">
					</el-date-picker>
				</el-form-item>
			</el-col>
			<el-col :xs="24" :sm="8" :md="6" :lg="4" :xl="3">
				<el-form-item :label="$t('kuponi.pravac')" class="width-full">
					<el-select v-model="kuponi.pravac" :disabled="id > 0">
						<el-option
								key="1"
								label="Polazak"
								value="polazak">
						</el-option>
						<el-option
								key="2"
								label="Povratak"
								value="povratak">
						</el-option>
						<el-option
								key="3"
								label="Oba"
								value="oba">
						</el-option>
					</el-select>
				</el-form-item>
			</el-col>
		</el-row>

		<el-row>
			<el-col class="col-xl-12" v-if="!(id > 0)">
				<el-form-item size="large">
					<el-button @click="generatorKupona()" :disabled="!kuponi.vrsta || !kuponi.kolicina || !kuponi.sablon">{{ $t('kuponi.simulacjaPrikazaKupona') }}</el-button>
				</el-form-item>
			</el-col>
		</el-row>
		<el-row>
			<el-col>
				<el-form-item v-if="kuponi.multi" size="large">
					<span :key="typeof kod === 'object' ? kod[0] : kod" v-for="kod in kuponi.multi" class="p--r-10">
						<el-tag v-if="typeof kod === 'object' && kod[1]" @click="$utils('goTo', '/karte?brojKarte=' + kod[1])" :type="kod[1] ? 'danger' : 'info'" style="cursor: pointer">{{ kod[0] }}</el-tag>
						<el-tag v-else :type="id > 0 ? 'info' : ''">{{ typeof kod === 'object' ? kod[0] : kod }}</el-tag>
					</span>
				</el-form-item>
				<el-form-item v-if="kuponi.multi">
					<el-button @click="csvExport()" type="primary" icon="el-icon-download" :disabled="!kuponi.multi.length">CSV</el-button>
				</el-form-item>
			</el-col>
		</el-row>

		<el-row>
			<el-col>
				<el-form-item size="large">
					<el-button v-loading="onSubmitLoading" :disabled="onSubmitLoading || id > 0" type="success" @click="onSubmit">{{ $t('global.sacuvaj') }}</el-button>
					<el-button type="info" @click="$utils('goBack')">{{ $t('global.odustani') }}</el-button>
				</el-form-item>
			</el-col>
		</el-row>
	</el-form>
</template>

<script>
export default {
	name: 'kuponi-edit',
	data() {
		return {
			fullscreenLoading: true,
			onSubmitLoading: false,
			id: '',
			formCreate: '',
			value4: [],
			kuponi: {
				naziv: '',
				vrsta: 'multi', // fiksni, multi
				kolicina: 10,
				sablon: '',
				prefix: '',
				sufix: '',
				datum_vreme_od: null,
				datum_vreme_do: null,
				karta_datum_od: null,
				karta_datum_do: null,
				fiksni_popust: 0,
				procenat_popust: 0,
				fiksna_cena: 0,
				min_cena: 0,
				id_vezane_linije: [],
				multi: [],
				pravac: 'oba' // polazak, povratak, oba
			},
			rules: {
				naziv: [{ required: true, max: 250, trigger: 'blur', message: this.$t('message.obavezno') }],
				sablon: [{ required: true, trigger: 'blur', message: this.$t('message.obavezno') }],
				id_vezane_linije: [{ required: true, trigger: 'blur', message: this.$t('message.obavezno') }],
				datum_vreme_od: [{ required: true, trigger: 'blur', message: this.$t('message.obavezno') }],
				datum_vreme_do: [{ required: true, trigger: 'blur', message: this.$t('message.obavezno') }],
				karta_datum_od: [{ required: true, trigger: 'blur', message: this.$t('message.obavezno') }],
				karta_datum_do: [{ required: true, trigger: 'blur', message: this.$t('message.obavezno') }],
				fiksni_popust: [{ required: true, trigger: 'blur', message: this.$t('message.obavezno') }],
				procenat_popust: [{ required: true, trigger: 'blur', message: this.$t('message.obavezno') }],
				fiksna_cena: [{ required: true, trigger: 'blur', message: this.$t('message.obavezno') }],
				min_cena: [{ required: true, trigger: 'blur', message: this.$t('message.obavezno') }]
			},
			pickerOptionsDo: {
				firstDayOfWeek: 1,
				disabledDate(date) {
					let d = new Date();
					d.setDate(d.getDate() - 1);
					return date < d;
				}
			},
			pickerOptionsOd: {
				firstDayOfWeek: 1,
				disabledDate(date) {
					let d = new Date();
					d.setDate(d.getDate() - 1);
					return date < d;
				}
			},
			pickerOptionsKartaDo: {
				firstDayOfWeek: 1,
				disabledDate(date) {
					let d = new Date();
					d.setDate(d.getDate() - 1);
					return date < d;
				}
			},
			pickerOptionsKartaOd: {
				firstDayOfWeek: 1,
				disabledDate(date) {
					let d = new Date();
					d.setDate(d.getDate() - 1);
					return date < d;
				}
			}
		};
	},
	watch: {
		'kuponi.procenat_popust'(val) {
			if (val > 0) {
				this.kuponi.fiksni_popust = 0;
				this.kuponi.fiksna_cena = 0;
			}
		},
		'kuponi.fiksni_popust'(val) {
			if (val > 0) {
				this.kuponi.procenat_popust = 0;
				this.kuponi.fiksna_cena = 0;
			}
		},
		'kuponi.fiksna_cena'(val) {
			if (val > 0) {
				this.kuponi.procenat_popust = 0;
				this.kuponi.fiksni_popust = 0;
			}
		}
	},
	mounted() {
		this.id = this.$route.params.id;
		this.formCreate = this.id === 'create';

		this.$store.dispatch('setHeader', {
			title: this.$t('kuponi.kupon')
		});

		this.$utils('stopLoadingAfter', [this.getKuponi()]);
	},
	methods: {
		getKuponi() {
			if (!this.formCreate) {
				return this.$get('kuponi', { id: this.id }).then(data => {
					this.kuponi = data;
				});
			}
		},
		generatorKupona() {
			if (!this.kuponi.vrsta || !this.kuponi.kolicina || !this.kuponi.sablon)
				return;

			return this.$get('kuponiGenerisanje', {
				kolicina: this.kuponi.vrsta === 'fiksni' ? 1 : this.kuponi.kolicina,
				sablon: this.kuponi.sablon,
				prefix: this.kuponi.prefix,
				sufix: this.kuponi.sufix
			}).then(data => {
				if (this.kuponi.vrsta === 'fiksni')
					this.kuponi.kupon = data[0];
				this.kuponi.multi = data;
			});
		},
		csvExport() {
			let arrData = this.kuponi.multi;
			let csvContent = 'data:text/csv;charset=utf-8,';
			csvContent += arrData.join('\n').replace(/(^\[)|(\]$)/gm, '');

			const data = encodeURI(csvContent);
			const link = document.createElement('a');
			link.setAttribute('href', data);
			let nameFile = this.kuponi.prefix + this.kuponi.sablon + this.kuponi.sufix;
			link.setAttribute('download', nameFile + '.csv');
			link.click();
		},
		onSubmit() {
			this.onSubmitLoading = true;
			return this.$refs.form.validate().then(() => {
				let data = JSON.stringify(this.kuponi);

				if (this.formCreate)
					this.$save('kuponi', data);
				else
					this.$update('kuponi', { id: this.id }, data);
			}).catch((err) => {
				this.onSubmitLoading = false;
				return err;
			});
		}
	},
	components: {
		selectVezaneLinije: require('../../components/select/vezaneLinije').default
	}
};
</script>


<style scoped lang="scss">
	.clear-marigin-padding {
		padding-left: 15px;
		> div {
			&:last-child {
				padding-right: 4px;
			}
			padding: 0px;
			margin: 0px;
			.el-form-item {
				padding-right: 0px;
			}
		}
	}
</style>
